<template>
  <div class="order-detail">
    <h2 class="sr-only">{{ $t('views.orders.detail.title') }}</h2>

    <loader v-if="is.loading"/>

    <template v-else>
      <div class="order-header">
        <dl>
          <div>
            <dt>{{ $t('components.order.header.date.label')  }}</dt>
            <dd>{{ moment(entity.created).format('DD.MM.YYYY HH:mm:ss') }}</dd>
          </div>
          <div>
            <dt>{{ definition.$properties.orderId.translations[$i18n.locale].name }}</dt>
            <dd>{{ entity.orderId }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.deliverytype.label') }}</dt>
            <dd v-if="entity.configuration.deliveryType">{{ $t(`components.order.configuration.delivery.type.${entity.configuration.deliveryType.toLowerCase()}`) }}</dd>
          </div>

          <div>
            <dt>{{ $t('components.order.header.paymenttype.label') }}</dt>
            <dd>{{ entity.configuration.payrexxPaymentType }}</dd>
          </div>

          <div>
            <dt><span v-html="entity.executedBasket.priceDetail.totalPriceLabel[$i18n.locale]"></span></dt>
            <dd>{{ $n(entity.executedBasket.priceDetail.totalPrice, 'currency') }}</dd>
          </div>

          <div>
            <dt>{{ definition.$properties.orderState.translations[$i18n.locale].name }}</dt>
            <dd>
              <b-badge :variant="orderStateBadgeVariants[entity.orderState]">{{ valueFormatter(definition, 'orderState', entity.orderState).value }}</b-badge>
            </dd>
          </div>
        </dl>
      </div>

      <div class="row align-items-center mt-3">
          <div class="col-6">
          <span class="pl-3">Mail Typ</span>
          </div>
          <div class="col-4">
           Versand Datum
          </div>
      </div>

      <div class="card mt-3" v-for="(transaction,index) in emailTransactions" :key="index">
        <div class="card-body">
        <div class="row align-items-center">
          <div class="col-6">
          {{ $t(`components.order.transactionmailtype.${transaction.emailType.toLowerCase(9)}`) }}
          </div>
          <div class="col-4">
          <div v-for="(date,index) in transaction.mailSent" :key="index">
              {{ moment(date).format('DD.MM.YYYY HH:mm:ss') }}
          </div>
          </div>
          <div class="col-2">
            <button class="btn btn-primary" @click="resendMailModal(transaction.id)">Erneut versenden</button>
          </div>
        </div>
        </div>
      </div>
      <b-modal
      :id="resendMailConfirmationModal.id"
      v-model="resendMailConfirmationModal.show"
      size="md"
      centered
      scrollable
      no-fade
      header-bg-variant="warning"
      header-text-variant="black"
    >
      <template #modal-header-close><b-icon icon="close"/></template>
      <template #modal-title>Mail erneut senden</template>

      <p>Soll das Mail erneut versendet werden?</p>

      <template #modal-footer>
        <template v-if="resendMailConfirmationModal.show">
          <b-button variant="light" @click="closeResendMailConfirmationModal">Nein</b-button>
          <b-button variant="danger" @click="resendMail(resendMailConfirmationModal.item)">Ja</b-button>
        </template>
      </template>
    </b-modal>
    </template>
  </div>
</template>

<script>
import { ORDER_STATES, ORDER_STATES_BADGES_VARIANTS } from '@/constants'

import { valueFormatter } from '@/assets/js/helper/entity'

import orderEmailTransactionService from '@/$plugins/services/order-email-transaction-service'

import MixinEntityBase from '@/components/mixins/EntityBase'
import Loader from '@/components/Loader'
import moment from 'moment'

export default {
  name: 'Orders.Detail',
  mixins: [MixinEntityBase],
  components: {
    Loader
  },
  data () {
    return {
      orderStateBadgeVariants: Object.keys(ORDER_STATES)
        .reduce((variants, sKey) => Object.assign(variants, { [ORDER_STATES[sKey]]: ORDER_STATES_BADGES_VARIANTS[sKey] }), {}),
      moment,
      emailTransactions: [],
      resendMailConfirmationModal: {
        id: 'resendMailConfirmationModal',
        show: false,
        item: null
      }
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.eParent.key}/is`]
    },
    definition () {
      return this.$store.getters[`${this.eParent.key}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.eParent.key}/getEntityUnwrapped`](this.eParent.id) || {}
    }
  },
  async mounted () {
    this.emailTransactions = await orderEmailTransactionService.getEmailTransactions(this.eParent.id)
    this.emailTransactions.sort((a, b) => new Date(a.mailSent) - new Date(b.mailSent))
  },
  methods: {
    valueFormatter,
    resendMailModal (transactionId) {
      this.resendMailConfirmationModal.item = transactionId
      this.resendMailConfirmationModal.show = true
    },
    closeResendMailConfirmationModal () {
      this.resendMailConfirmationModal.item = null
      this.resendMailConfirmationModal.show = false
    },
    resendMail (transactionId) {
      orderEmailTransactionService.resendEmail(transactionId).then()
      this.closeResendMailConfirmationModal()
    }
  }
}
</script>

<style lang="scss">
.order-detail {
  .order-header {
    padding: $spacer;
    background-color: $gray-200;

    dl {
      display: grid;
      grid-gap: $spacer * 0.75;
      grid-auto-flow: column;
      grid-template-columns: auto;
      margin: 0;

      dd {
        margin: 0;
      }
    }
  }

  .order-basket {
    margin-top: $spacer * 2;
  }

  .order-configuration {
    margin-top: $spacer * 2;
  }
}
</style>
