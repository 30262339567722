import http from '@/$plugins/http'
import { STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

export default {
  async getEmailTransactions (orderId) {
    const result = await http({
      method: 'get',
      url: `/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/ordermailtransactions/fromorder/${orderId}/all`
    })

    if (result.data.success) {
      return result.data.result
    }
    console.log(result.data.errorMessage)
    return null
  },
  async resendEmail (transactionEmailId) {
    const result = await http({
      method: 'post',
      url: `/${STOREMODULE_ENDPOINTMODULE_MAP.order}/api/ordermailtransactions/resendmail/${transactionEmailId}`
    })

    if (result.data.success) {
      return result.data.result
    }
    console.log(result.data.errorMessage)
    return null
  }
}
